import { EEnvironmentId } from '@calaosoft/osapp/model/application/EEnvironmentId';
import { IEnvironment } from '@calaosoft/osapp/model/environment/IEnvironment';
import { IStatusBarConfig } from '@calaosoft/osapp/model/statusBar/IStatusBarConfig';

export const APP_ID = "texcom";
export const APP_CORE_DATABASE_PREFIX = `${APP_ID}_core_`;

export const environment: IEnvironment = {
	production: false,
	id: EEnvironmentId.beta,
	description: "BETA",
	cloud_url: "https://cloudbeta.mobile.calaosoft.fr",
	coreRoleAppConfig: `${APP_CORE_DATABASE_PREFIX}common_app_configs`,
	statusBar: { backgroundColorByHexString: "#f43a2c" },
	thumbnailLogo: "assets/icon/texcom-icon-beta.png",

	cloud_api_suffix: "/api/",
	cloud_dms_suffix: "/dms/texcom/documents/",
	cloud_api_data_suffix: `/api/apps/${APP_ID}/data/`,
	cloud_api_apps_suffix: `/api/apps/${APP_ID}/`,

	dms: {},

	appCredentials: {
		login: "texcom",
		password: "iperot84"
	},

	unlockRequired: true,

	GOOGLE_MAPS_BROWSER_API_KEY: "AIzaSyAfz9S9AFGXbaBuHB8iBED9OR7IUdhcxZY",
	GOOGLE_MAPS_IOS_API_KEY: "AIzaSyAuXftS2xRyvNE7ELHFF0rsEyu5mRX7vng",
	API_KEY: "ODkwMWU1OTk2MThmNGExN2I4MDliOGZjN2ZmNzE1YTc"
};

export const statusBar: IStatusBarConfig = { backgroundColorByHexString: "#f43a2c" };

/** Url de connexion vers l'ERP Textilot. */
export const TEXPLUSERP_URL = "https://texpluserp-extra.textilot.fr/account/login?returnUrl=%2Ffrais%2Fboard&embedded=true&token={{externalToken}}";